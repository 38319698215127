import React from 'react'
import content from 'assets/contents/content-es'
import awsLogo from 'assets/img/aws.png'
import stripeLogo from 'assets/img/stripe.png'
import woocLogo from 'assets/img/woocommerce.png'
import mlLogo from 'assets/img/mercadolibre.png'
import { Container, Row, Col } from 'reactstrap'

export default function CenteredGrid() {

  return (
    <div id='section-one' style={{paddingTop: 80}}>
      <Container>
        <h2>{content.platform.title}</h2>
        <Row xs='1' sm='2' lg='4'>
          <Col className='text-center p-5'>
            <div className='text-center w-50 m-auto' style={{height: 120}}>
              <img
                alt="aws-logo"
                className="img-fluid"
                src={awsLogo}
                />
            </div>
            <p>{content.platform.aws}</p>
          </Col>
          <Col className='text-center p-5'>
            <div className='text-center w-75 m-auto' style={{height: 120}}>
              <img
                alt="aws-logo"
                className="img-fluid"
                src={stripeLogo}
                />
            </div>
            <p>{content.platform.stripe}</p>
          </Col>
          <Col className='text-center p-5'>
            <div className='text-center w-75 m-auto' style={{height: 120}}>
            <img
              alt="aws-logo"
              className="img-fluid"
              src={mlLogo}
              />
            </div>
            <p>{content.platform.ml}</p>
          </Col>
          <Col className='text-center p-5'>
            <div className='text-center w-75 m-auto' style={{height: 120}}>
              <img
                alt="aws-logo"
                className="img-fluid"
                src={woocLogo}
                />
            </div>
            <p>{content.platform.woo}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}



