import React,  { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, Button, Collapse, Offcanvas, OffcanvasHeader, OffcanvasBody, Modal, ModalHeader, ModalBody } from 'reactstrap'
import content from 'assets/contents/content-es'
//import TrialDialog from './TrialDialog'

const tiers = [
  {
    bizor_descriptor: "saas_vg_lite",
    title: 'Lite',
    usersPerPlan: 3,
    additionalUsers: 0,
    description: [
      '2 Usuarios',
      '1 Compañía',
      '1 Sucursal',
    ],
    description2: [
      {name: 'Facturación Ilimitada', bullets: []},
      {name: 'Notificaciones Vía Email', bullets: []},
      {name: 'Indicadores Básicos', bullets: []},
      {name: 'Listado de Precios Único', bullets: []},
      {name: 'Compras/Inventarios (Estandar)', bullets: []},
      {name: 'Roles Básicos', bullets: []},
      {name: 'Soporte Standard', bullets: []},
      {name: 'Libros de Compra y Venta', bullets: ["Libros de Compras Y Ventas"]},
    ],
    buttonText: 'Comienza Gratis',
    buttonVariant: 'outlined',
  },
  {
    bizor_descriptor: "saas_vg_pro",
    title: 'Pro',
    subheader: 'Más popular',
    usersPerPlan: 5,
    additionalUsers: 0,
    description: [
      '5 Usuarios',
      '1 Compañía',
      '3 Sucursales',
    ],
    description2: [
      {name: 'Facturación Ilimitada', bullets: []},
      {name: 'Notificaciones Vía Email', bullets: []},
      {name: 'Indicadores Pro', bullets: []},
      {name: 'Hasta 3 Listados Precios', bullets: []},
      {name: 'Línea de Tiempo de Ventas/Compras', bullets: []},
      {name: 'Compras/Inventarios (Avanzado)', bullets: []},
      {name: 'Historico de precios', bullets: []},
      {name: 'Roles personalizables', bullets: []},
      {name: 'Módulo de Contabilidad', bullets: [
        "Genera tus libros de Compras Y Ventas con un solo click (Formato SENIAT)",
        "Genera tus archivo de retenciones (Formatos exportables .txt y .xlm SENIAT)",
        "Además obten tus balances y estados de resultados automáticamente"]},
    ],
    buttonText: 'Comienza Gratis',
    buttonVariant: 'contained',
  },
/*   {
    id: '300',
    bizor_descriptor: null,
    title: 'Personalizado',
    price: 'Contactar Ventas',
    annual: 'Contactar Ventas',
    description: [
      'Compañías a la medida',
      'Plataforma Dedicada',
      'Soporte Dedicado',
    ],
    description2: [
      'Creación de Módulos Personalizados',
      'Definición de Funciones a la Medida',
    ],
    buttonText: 'Comienza Gratis',
    buttonVariant: 'outlined',
  } */
];

export default function Pricing() {
  const [annual,setAnnual] = useState(false)
  const [plans, setPlans] = useState(tiers)
  const [products, setProducts] = useState([])
  const [expanded, setExpanded] = useState({0: true, 1: true, 2: true})
  const [info, setInfo] = useState(null)
  const [openDemo,setOpenDemo] = useState(false)


  const toggleDemo = () => setOpenDemo(!openDemo)
  const matches = false// useMediaQuery('(max-width:600px)');
  //console.log(matches)
  
  useEffect(() => {
    fetch('https://h0vcba5la7.execute-api.us-east-2.amazonaws.com/prod/plans')
    .then(response => response.json())
    .then(data => {
      
      data.plans.forEach(p => {
        p.pricesObject = p.prices.reduce((acc,cur) => ({...acc, [cur.metadata.usage]: cur}), {})
      })
      setProducts(data.plans)
      setPlans(prev => prev.reduce((acc, p) => {
        const stripePlan = data.plans.find(d => d.metadata.bizor_descriptor === p.bizor_descriptor)
        if(!stripePlan) return acc
        
        p.id = stripePlan.id
        p.price = stripePlan.pricesObject["flat_rate-monthly-base"]
        p.pricePerUser = stripePlan.pricesObject["per_seat-monthly-user"]
        return [...acc, p]
      }, []))
    })
  },[])

  useEffect(() => {
    if(matches) setExpanded({0: false, 1: false, 2: false})
  },[matches])

  const handleFreeTrial = (params) => {
    const plan = params.plan
    if(!plan || !plan.price.id) return
    const searchParams = new URLSearchParams({
      bizor_descriptor: plan.bizor_descriptor,
      productId: plan.id,
      priceId: plan.price.id,
      pricePerUserId: plan.pricePerUser.id,
      usersPerPlan: plan.usersPerPlan,
      additionalUsers: 0
    })
    //process.env.NODE_ENV === 'development' ? `http://localhost:4030/signup?${searchParams.toString()}` : 
    window.open(`https://app.bizor.io/signup?${searchParams.toString()}`, '_blank')
    //console.log('handleFreeTrial', params)
    //setParams(params)
    //setOpen(true)
  }

  const handleInfo = (inf) => (event) => {
    setInfo(inf)
  }

  return (
    <React.Fragment>
      {/* Hero unit */}
      <Container style={{paddingTop: 100, paddingBottom: 40}}>
        <Row>
          <Col className='p-4' sm={4}>
            <h2 className='fst-italic mt-3 fw-bold mb-0' >BIZOR S</h2>
            <h5 className="text-start text-uppercase" style={{color: '#777'}}>gestión y ventas</h5>
            <img
              alt="bizor-s"
              src={require("assets/img/bizor-s-product-1.png")}
              className="rounded img-fluid"
            />
            <h2 className='text-center mt-3' >{content.pricing.title}</h2>
            <h3 className='text-center mb-2'>{content.pricing.subTitle}</h3>
            <h5 className='text-center text-primary'>{content.general.noCardNeeded}</h5>

            
          </Col>
          {plans.map((plan,key) => (
            // Enterprise card is full width at sm breakpoint
            <Col className='p-4' key={key} xs={12} sm={key === 2 ? 12 : 4} >
              <Card className='bg-transparent' >
                <h4 className={'text-uppercase text-center fw-bolder rounded-top mt-0 mb-0 ' + (key === 1 ? "text-bg-primary" : "text-bg-light")} >
                  {plan.title}&nbsp;
                  {key === 1 ? <i className='bi bi-star' style={{color: 'yellow'}} /> : null}
                </h4>
                <CardBody /* className={classes['top_' + key]} */ className='bg-white rounded-bottom'>
                  { key !== 2 ? 
                    <div className='d-flex justify-content-center align-items-baseline'>
                      <h3 className='fw-bold'>
                        USD {plan.price ? plan.price.unit_amount/100 : ''}/
                      </h3>
                      <h4>
                          {annual ? 'año' : 'mes'}
                      </h4>
                    </div>
                    :
                    <div>
                      <p color="textPrimary" style={{fontSize:'1.7rem', fontWeight: 700, marginTop: 15}}>
                        {plan.price}
                      </p>
                    </div>
                    }
                  {/* MAIN FEATURES */}
                  {plan.description.map((line,k) => (
                    <div key={k} className='d-flex justify-content-center align-items-baseline fw-bold'>
                      <i className='bi bi-check2-circle fs-5' />
                      <p className='ms-2' >{line}</p>
                    </div>
                  ))}
                  <br />
                  <div className='d-flex justify-content-center'>
                    <Button className='action-button-filled' onClick={() =>  key < 2 ? handleFreeTrial({plan}) : {}} >
                      {key === 2 ? "Contactar a Ventas" : content.general.startButton}
                    </Button>
                  </div>
                  <br />
                  {/* EXPAND BUTTON */}
{/*                   <div className='d-flex justify-content-center align-items-center mb-3'>
                    Ver más&nbsp;&nbsp;&nbsp;
                    <Button
                      onClick={handleExpandClick(key)}
                      aria-expanded={expanded[key]}
                      aria-label="show more"
                      color='light'
                    >
                      <i className='bi bi-chevron-expand' />
                    </Button>
                  </div> */}

                  {/* SECONDARY FEATURES */}
                  <Collapse isOpen={expanded[key]} >
                    <div style={{minHeight: 400}}>
                      {plan.description2.map((line, key) => (
                        <div key={'d' + key} className='d-flex align-items-center ps-4 underline-text-hover' onClick={handleInfo(line)}>
                          <i className='bi bi-check-lg fs-4' />
                          &nbsp;
                          <span>{line.name}</span>
                        </div>
                      ))}
                    </div>
                    
                    <br />
                    { key !== 2 && (
                      <h5 className='text-center'>
                        Usuarios adicionales (USD {plan.pricePerUser ? plan.pricePerUser.unit_amount/100 : ''}/usuario)
                      </h5>
                    )}
                  </Collapse>
{/*                   <div className='d-flex justify-content-center'>
                    <Button className='action-button-filled' onClick={() => key === 2 ? handleFreeTrial({plan}) : {}} >
                      {key === 2 ? "Contactar a Ventas" : content.general.startButton}
                    </Button>
                  </div> */}
                  </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        
      </Container>
      {/* End hero unit */}
      {/* <div display='flex' justifyContent='space-around'>
        <p component="div" style={{fontWeight: 600}}>
            <Col component="label" container alignItems="center" spacing={1}>
                <Col item>Pago Mensual</Col>
                <Col item>
                    <IOSSwitch checked={annual} onChange={handleBillingPeriod} name="checkedC" />
                </Col>
                <Col item>Pago Anual </Col>
                <Col item><Chip label="Ahorras el 10%" className={classes.title2} /></Col>
            </Col>
        </p>
      </div> */}
      
      <div className='text-bg-light p-5'>
        <Container>
          <Row>
            <Col xs={12}><h3>Complementos</h3></Col>
            {/* CONTABILIDAD RAPIDA */}
            {/* <Col xs={12} className='border rounded d-flex justify-content-between p-3 mb-4'>
                <div>
                  <h5>Contabilidad Rápida</h5>
                  <p className='mb-0 text-secondary'>Obtén tus libros de compras, ventas, balance general y estados de resultados con un solo click!</p>
                </div>
                <h5>USD 9/mes</h5>
            </Col> */}
            {/* AUTOGESTION */}
            <Col xs={12} className='border rounded d-flex justify-content-between p-3 mb-4'>
              <div className="w-75">
                <h5>Portal de Autogestión</h5>
                <p className='mb-0 text-secondary'>
                  Cambia la forma en que te comunicas e interactúas con tus clientes. Te ofrecemos una interfaz nativa de AUTOGESTIÓN para que tus clientes siempre tengan acceso tu tienda.
                  <Button size='sm' color="link" onClick={toggleDemo}>
                    Ve como funciona aquí!
                  </Button>
                </p>
              </div>
              <div className="d-flex justify-content-end w-25" style={{minWidth: 160}}>
                <h5>USD 35/mes</h5>
              </div>
            </Col>
            {/* AUTOMATIZACIONES */}
            <Col xs={12} className='border rounded d-flex justify-content-between p-3 mb-4'>
                <div className="w-75">
                  <h5>Automatización de Tareas</h5>
                  <p className='mb-0 text-secondary'>Delega la ejecución de operaciones de forma automatizada.</p>
                </div>
                <div className="d-flex justify-content-end w-25">
                  <h5>USD 3/función/mes</h5>
                </div>
                
            </Col>
            {/* Conexión Para Terceros (APIs) */}
            <Col xs={12} className='border rounded d-flex justify-content-between p-3 mb-4'>
                <div className="w-75">
                  <h5>Conexión Para Terceros (APIs)</h5>
                  <p className='mb-0 text-secondary'>Expande tu radio de acción conectando con otras aplicaciones y plataformas de terceros. Podemos conectar tus datos con plataformas como MercadoLibre para que los precios y disponibilidades de tus publicaciones se actualicen automáticamente.</p>
                </div>
                <div className="d-flex justify-content-end w-25">
                  <h5>USD 15/mensual/conexión </h5>
                </div>
            </Col>
            
          </Row>
        </Container>
      </div>
      {/* <TrialDialog setOpen={setOpen} open={open} params={params} /> */}
      <Offcanvas
        backdrop={false}
        direction="bottom"
        isOpen={!!info}
        >
        <OffcanvasHeader style={{height: 60}} toggle={() => setInfo(null)}>
          <h4><strong>{info && info.name}</strong></h4>
        </OffcanvasHeader>
        <OffcanvasBody>
          {
            !!info && info.bullets && info.bullets.length > 0 && 
            <ul>
              {
              info.bullets.map(b => (
                <li><p className="fw-light mb-2">{b}</p></li>
              ))
              }
            </ul>
          }
        </OffcanvasBody>
      </Offcanvas>
      <Modal isOpen={openDemo} toggle={toggleDemo} size="xl" style={{padding: 16}}>
        <ModalHeader toggle={toggleDemo}>Factura en Pocos Pasos</ModalHeader>
        <ModalBody >
          <div className="youtube-container">
            <iframe
              className="youtube-responsive-iframe"
              src="https://bizor-docs.s3.us-east-2.amazonaws.com/bizor_demos/AutogestionPocosPasos.mp4"
              title="Demo Autogestión"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              />
          </div>
          
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}